import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, RecentBlogs, SectionHeader } from '../components';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@material-ui/core';
import defaultBlogImage from '../../static/blog_post.gif';
import { useContentfulImage } from '../utils/useContentfulImage';

const BlogPage = ({ data, location }) => {
	const [ active, setActive ] = useState('');
	useEffect(() => {
		setActive('/blogs');
	}, []);
	useEffect(
		() => {
			setActive('/#blogs');
		},
		[ location.href ]
	);
	// console.log(JSON.parse(data.contentfulBlogPost.body.raw));
	// const options = {
	// 	renderNode: {
	// 		[BLOCKS.EMBEDDED_ASSET]: (node) => {
	// 			const asset = useContentfulImage(node.data.target.sys.id);
	// 			console.log(asset);
	// 			if (asset) {
	// 				return <img src={asset.node.fluid.src} alt="" />;
	// 			}
	// 		}
	// 	}
	// };

	return (
		<Layout setActive={setActive} active={active} location={location}>
			<section id="blog">
				<Container>
					<Grid container justifyContent="center" spacing={4}>
						<Grid item md={8}>
							<Card elevation={0}>
								
								<CardContent>
									<Typography variant="h4" component="h2">
										{data.contentfulBlogPost.title}
									</Typography>
									<Typography style={{marginBottom:'24px'}} variant="caption" color="textSecondary" component="p">
										{data.contentfulBlogPost.publishedDate}
									</Typography>
										<CardMedia
											style={{marginBottom:'24px'}}
											component="img"
											alt={data.contentfulBlogPost.imageUrl?.file.fileName}
											height="300"
											image={data.contentfulBlogPost.imageUrl?.file.url || defaultBlogImage}
											title={data.contentfulBlogPost.imageUrl?.file.fileName}
										/>
									<div className="blogBody">
										{documentToReactComponents(JSON.parse(data.contentfulBlogPost.body.raw))}
									</div>
								</CardContent>
							</Card>
						</Grid>
						<Grid item md={4}>
							<SectionHeader text="Recent Blogs" />
							<RecentBlogs />
						</Grid>
					</Grid>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPage;

export const query = graphql`
	query($slug: String!) {
		contentfulBlogPost(slug: { eq: $slug }) {
			title
			imageUrl {
				file {
					url
					fileName
				}
			}
			publishedDate(formatString: "MMM Do, YYYY")
			body {
				raw
				
			}
		}
	}
`;
